import {
  DIVISIONS_LIST_REQUEST,
  DIVISIONS_LIST_SUCCESS,
  DIVISIONS_LIST_FAIL,
} from '../constants/divisionsConstants'

const initialDivisions = [
  {
    id: 'd1',
    title: 'Rx’d (all ages)',
    content:
      'This is an open, All-Ages division. Participants will appear on an Rx’d leaderboard and have an opportunity to earn the Levels 2-4 badge and shirt variations. Masters 35+ individuals may also choose to participate in this open division instead of the Masters 35+ specific divisions, however scores will appear on the leaderboard for the selected division.',
  },
  {
    id: 'd2',
    title: 'Scaled (all ages)',
    content:
      'This is an open, All-Ages division that uses a scaled version of the Rx’d division workout. Participants will appear on the Scaled leaderboard and have an opportunity to earn the Level 1 badge and shirt variation. Masters 35+ individuals may also choose to participate in this open division instead of the Masters 35+ specific divisions, however scores will appear on the leaderboard for the selected division.',
  },
  {
    id: 'd3',
    title: 'Masters 35+',
    content:
      'Participants must be 35 years old by July 9th 2021 to participate in this division. Participants will appear on the Masters 35+ leaderboard and have an opportunity to earn the Levels 2-4 badge and shirt variations.',
  },
  {
    id: 'd3',
    title: 'Masters 35+ Scaled',
    content:
      'Participants must be 35 years old by July 9th 2021 to participate in this division. This division uses a scaled version of the Masters 35+ division workout. Participants will appear on the Masters 35+ Scaled leaderboard and have an opportunity to earn the Level 1 badge and shirt variation.',
  },
]

export const listDivisions = () => async (dispatch) => {
  try {
    dispatch({ type: DIVISIONS_LIST_REQUEST })

    // const { data } = await axios.get('/api/divisions')

    const data = initialDivisions // temporary

    dispatch({ type: DIVISIONS_LIST_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: DIVISIONS_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
