import Styles from './SmallLogo.module.scss'

const SmallLogo = ({ topTitle, bottomTitle, className, imageName }) => {
  return (
    <div className={[Styles.box, className ? className : ''].join(' ')}>
      {topTitle && <h3 className={Styles.top}>{topTitle}</h3>}
      {imageName ? (<a href=" https://onetreeplanted.org">
              <div className={imageName ? Styles[imageName] : Styles.logo}></div>
            </a>)  : (<div className={imageName ? Styles[imageName] : Styles.logo}></div>)}
      {bottomTitle && <h3 className={Styles.bottom}>{bottomTitle}</h3>}
    </div>
  )
}

export default SmallLogo
