import Styles from './ErrorIndicator.module.scss'

const ErrorIndicator = () => {
  return (
    <div className={Styles.errorIndicator}>
      <span className={Styles.boom}>BOOM!</span>
      <span>something has gone terribly wrong</span>
      <span>(but we already sent our specialists to fix it)</span>
    </div>
  )
}

export default ErrorIndicator
