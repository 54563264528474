import React, { useState, useRef } from 'react'
import Select from 'react-select'
import { useDispatch } from 'react-redux'

import { Link, useHistory } from 'react-router-dom'
import { Button } from 'react-bootstrap'

import Logo from '../../components/Logo'
import SignContainer from '../../components/UI/SignContainer'

import { addTshirtSizeNewUserDetails, register } from '../../actions/userAction'

import Styles from './SelectTshirt.module.scss'

const sizes = [
  { value: 'S', label: 'S' },
  { value: 'M', label: 'M' },
  { value: 'L', label: 'L' },
  { value: 'XL', label: 'XL' },
  { value: 'XXL', label: 'XXL' },
  { value: 'XXXL', label: 'XXXL' },
]

const SelectTshirt = () => {
  let history = useHistory()
  const [size, setSize] = useState('')
  const [showWarning, setShowWarning] = useState(false)
  const sizeSelectRef = useRef()
  const dispatch = useDispatch()

  const onSureHandler = () => {
    dispatch(register())
    history.push('/success')
  }

  const onDontWantHandler = () => setShowWarning(true)

  const submitHandler = (e) => {
    e.preventDefault()

    if (size) {
      dispatch(addTshirtSizeNewUserDetails(size))
      dispatch(register())
      history.push('/success')
    } else {
      setShowWarning(true)
    }
  }

  return (
    <div className={Styles.shirt}>
      <div className={Styles.left}>
        <div className={Styles['big-logo']}>
          <Link to='/'>
            <Logo isBtwb />
          </Link>
        </div>

        <div className={Styles.shirtBig}>
          <div className={Styles.icon}></div>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ipsum
            pharetra, id pharetra leo. Justo sed non et aenean sed consequat
            natoque.
          </p>
        </div>
      </div>
      <div className={Styles.right}>
        <div className={Styles['small-logo']}>
          <Link to='/'>
            <Logo isBtwb />
          </Link>
        </div>
        <SignContainer className={Styles.box}>
          <div className={Styles.shirtSmall}>
            <div className={Styles.icon}></div>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ipsum
              pharetra, id pharetra leo.
            </p>
          </div>
          <div className={Styles.formBox}>
            <h2>Receive a t-shirt after completing the challenge</h2>
            <p className={Styles.tip}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ipsum
              pharetra, id pharetra leo. Justo sed non et aenean sed consequat
              natoque.
            </p>
            <div className={Styles.price}>
              <h3>price</h3>
              <p>$30</p>
            </div>
            <form className={Styles.form} onSubmit={submitHandler}>
              <div className={Styles.size}>
                <div
                  className={Styles.title}
                  onClick={() => sizeSelectRef.current.focus()}
                >
                  t-shirt size&nbsp;<span>*</span>
                </div>
                <Select
                  className={Styles.sizeItem}
                  classNamePrefix='sizeItem'
                  ref={sizeSelectRef}
                  menuPlacement='auto'
                  placeholder='Select your size'
                  menuPosition='fixed'
                  options={sizes}
                  onChange={(size) => setSize(size.value)}
                  components={{
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>

              <Button
                variant='primary'
                className={Styles.btn}
                type='submit'
                block
              >
                Buy
              </Button>
              <div className={Styles.refusal}>
                {showWarning ? (
                  <div className={Styles.warning}>
                    <p>
                      You will not be able to purchase a challenge t-shirt after
                      completing the sign-up process. Are you sure?
                    </p>
                    <div className={Styles.action}>
                      <Button
                        variant='danger'
                        type='button'
                        className={Styles.button}
                        onClick={onSureHandler}
                      >
                        Yes, I’m sure
                      </Button>

                      <Button
                        variant='outline-primary'
                        type='button'
                        className={Styles.button}
                        onClick={() => setShowWarning(false)}
                      >
                        No, I'll think about it
                      </Button>
                    </div>
                  </div>
                ) : (
                  <span onClick={onDontWantHandler}>
                    I don't want to get this t-shirt.
                  </span>
                )}
              </div>
            </form>
          </div>
        </SignContainer>
      </div>
    </div>
  )
}

export default SelectTshirt
