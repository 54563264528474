// import axios from 'axios'
import {
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_RESET,
  NEW_USER_DETAILS_SAVE,
  NEW_USER_DETAILS_ADD_DIVISION,
  NEW_USER_DETAILS_ADD_TSHIRT,
  NEW_USER_DETAILS_RESET,
} from '../constants/userConstants'

export const login = (email, password) => async (dispatch) => {
  try {
    dispatch({ type: USER_LOGIN_REQUEST })

    // const config = {
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    // }

    // const { data } = await axios.post(
    //   '/api/users/login',
    //   { email, password },
    //   config
    // )

    const data = { email, password } // temporary

    dispatch({ type: USER_LOGIN_SUCCESS, payload: data })

    localStorage.setItem('userInfo', JSON.stringify(data))
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const logout = () => (dispatch) => {
  localStorage.removeItem('userInfo')
  dispatch({ type: USER_LOGOUT })
  dispatch({ type: USER_REGISTER_RESET })
}

export const register = () => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_REGISTER_REQUEST })

    const {
      newUserDetails: { user },
    } = getState()

    // const config = {
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    // }

    // const { data } = await axios.post(
    //   '/api/users',
    //   { name, email, password },
    //   config
    // )

    dispatch({ type: USER_REGISTER_SUCCESS, payload: user })
    dispatch({ type: USER_LOGIN_SUCCESS, payload: user })
    dispatch({ type: NEW_USER_DETAILS_RESET })

    localStorage.setItem('userInfo', JSON.stringify(user))
  } catch (error) {
    dispatch({
      type: USER_REGISTER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const saveNewUserDetails = (data) => (dispatch) => {
  dispatch({ type: NEW_USER_DETAILS_SAVE, payload: data })
}

export const addDivisionNewUserDetails = (division) => (dispatch) => {
  dispatch({ type: NEW_USER_DETAILS_ADD_DIVISION, payload: division })
}

export const addTshirtSizeNewUserDetails = (size) => (dispatch) => {
  dispatch({ type: NEW_USER_DETAILS_ADD_TSHIRT, payload: size })
}
