export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'
export const USER_LOGOUT = 'USER_LOGOUT'

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST'
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS'
export const USER_REGISTER_RESET = 'USER_REGISTER_RESET'
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL'

export const NEW_USER_DETAILS_SAVE = 'NEW_USER_DETAILS_SAVE'
export const NEW_USER_DETAILS_ADD_DIVISION = 'NEW_USER_DETAILS_ADD_DIVISION'
export const NEW_USER_DETAILS_ADD_TSHIRT = 'NEW_USER_DETAILS_ADD_TSHIRT'
export const NEW_USER_DETAILS_RESET = 'NEW_USER_DETAILS_RESET'
