import React from 'react'

import { Container, Row, Col, Image } from 'react-bootstrap'

import Styles from './EarnedShirtScreen.module.scss'

const EarnedShirtScreen = () => {
  return (
    <Container fluid className={Styles.gradient}>
      <Row>
        <Col>
          <div className={Styles.shirt}>
            <Row>
              <Col
                md={{ span: 6, order: 1 }}
                xs={{ span: 12, order: 2 }}
                className={Styles.left}
              >
                <h2>Earned Series</h2>
                <p>
                  In the Earned Series ALL participants will submit a video of their effort. 
                  Leaderboard placing, t-shirt, and challenge badge are all tied to official video verification. 
                  A participant will NOT appear on a leaderboard, or receive an official challenge badge and t-shirt without a video. 
                  Participants will receive challenge badges and t-shirts (if purchased) that match the level of their effort. 
                  Earned Series challenges contain 4 different levels, each commemorating a specific milestone in an athlete’s fitness journey. 
                  Follow the standards and guidelines outlined below to ensure proper video verification so that you can truly earn it!
                </p>

                <h3>Equipment</h3>
                <p>
                  Participants must abide by the equipment requirements laid out below.
                </p>
                <ul className={Styles.equipments}>
                  <li>45/35-lb. Barbell (20 kg./15 kg.)</li>
                  <li>
                    Standard size bumper plates or weight plates of correct weight & clearly marked
                  </li>
                  <li>Collars</li>
                  <li>Visible clock, timer in frame, phone timers, apps & stopwatches are acceptable as long as they are in frame and clearly visible the entire time</li>
                  <li>A safe, unobstructed area to perform the workout</li>
                  <li>Chalk, weight belts, knee/elbow sleeves, wrist wraps and any style of shoe are permitted, but not required</li>
                </ul>

                <div className={Styles.standards}>
                  <h3>Video Submission Guidelines</h3>
                  <p>
                    The barbell & plates should be filmed in a way that allows the loads to be clearly identified. 
                    Videos need to be uncut & unedited in order to accurately display the performance. 
                    Videos shot with a fisheye lens or similar lens may be rejected, we recommend not using such lenses. 
                    The clock or timer must be visible during the entire workout. 
                    Phone timers, apps & stopwatches are acceptable as long as they are in frame and clearly visible the entire time. 
                    Ensure the athlete has ample space to safely perform all aspects of the workout & all potential obstructions have been removed. 
                    The video should be filmed in such a way as to clearly capture the entire range of motion required for the workout and allow for easy verification of the movement standards.
                  </p>
                  <p>
                    At the start of the video the athlete should be in frame, state their name, the challenge they are performing and which workout variation they choose to do (Rx’d, Scaled, etc.). 
                    Next, the barbell & plates should be filmed in a way that allows the loads to be clearly identified. 
                    Keep in mind everything needs to be filmed in one continuous, unedited video.
                  </p>
                  <p>
                    Videos need to be uploaded and attached to a btwb result no later than Tuesday, July 13, 2021 at 5pm PST. 
                    Be sure to allow sufficient time to submit your score and attach your video. 
                    Videos do NOT need to be attached at time of logging. 
                    However, they must be attached by the time the challenge officially closes.
                  </p>
                </div>

                <div className={Styles.video}>
                  <iframe 
                    className={Styles.content}
                    src="https://www.youtube.com/embed/6VIHUsF6w2A" 
                    title="YouTube video player" 
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                    allowfullscreen>
                  </iframe>
                </div>

                <div className={Styles.standards}>
                  <h3>Movement Standards</h3>
                  <p>
                    Each rep of the clean & jerk starts with the barbell on the ground & finishes with the barbell locked out overhead with the arms, hips & legs fully extended. 
                    When viewed from profile the barbell should be in line with the center of the body (or slightly behind the center), with the feet in line. 
                    Snatching the barbell is not allowed. 
                    The barbell must make contact with the shoulders in the front rack position before going overhead to finish the lift. 
                    Once in the front rack position a shoulder press, push press, push jerk or split jerk are all permitted. Touch & go reps are permitted. 
                    After achieving full lockout the barbell may be dropped from overhead, but the barbell must be fully settled on the ground before beginning the next rep. 
                    Excessive bouncing of the barbell is not permitted.
                  </p>
                </div>

                <div className={Styles.image}>
                  <Image
                    src='./images/demo.gif'
                    fluid
                    className={Styles.big}
                  />
                </div>
              </Col>

              <Col
                md={{ span: 6, order: 2 }}
                xs={{ span: 12, order: 1 }}
                className={Styles.right}
              >
                <h2>Workout Levels</h2>
                <div className={Styles.tshirt}></div>

                <h3 className={Styles.light}>Which Shirt Will You Earn?</h3>
                <h3>Rx’d</h3>
                <ul className={Styles.list}>
                  <li>Level 1: --</li>
                  <li>Level 2: Complete the workout Rx’d</li>
                  <li>Level 3: Complete the workout in 4:00 min or less</li>
                  <li>Level 4: Complete the workout in 2:00 min or less</li>
                </ul>

                <h3>Scaled</h3>
                <ul className={Styles.list}>
                  <li>Level 1: Complete the scaled version of the workout</li>
                </ul>

                <h3>Masters 35+</h3>
                <ul className={Styles.list}>
                  <li>Level 1: --</li>
                  <li>Level 2: Complete the workout Rx’d</li>
                  <li>Level 3: Complete the workout in 4:00 min or less</li>
                  <li>Level 4: Complete the workout in 2:00 min or less</li>
                </ul>

                <h3>Masters 35+ Scaled</h3>
                <ul className={Styles.list}>
                  <li>Level 1: Complete the scaled version of the workout</li>
                </ul>

                <div className={Styles.pdfs}>
                  <p><a href="https://s3.amazonaws.com/media.btwb.co/Earned+Series/grace_rules.pdf">Rules Guide PDF</a></p>
                  <p><a href="https://s3.amazonaws.com/media.btwb.co/Earned+Series/grace_lesson-plan.pdf">Gym Lesson Plan PDF</a></p>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default EarnedShirtScreen
