import {
  WORKOUTS_LIST_REQUEST,
  WORKOUTS_LIST_SUCCESS,
  WORKOUTS_LIST_FAIL,
} from '../constants/workoutsConstants'

const initialWorkouts = [
  {
    id: 'w1',
    title: "Rx'd (all ages)",
    weight: '135/95',
    description:
      'Complete 30 clean & jerks at the prescribed weight as fast as you can. Touch and go reps are permitted. The athlete must be standing at full knee, hip, and elbow extension at the top of each jerk in order for the rep to count, and before lowering the bar back to the floor for the next rep.',
    instructions:
      'The athlete begins standing tall in front of the barbell. Once the timer begins counting the athlete may reach down, grab the barbell & begin the workout. Upon completion of the final clean & jerk the workout is over. The athlete’s score is their total time.',
  },
  {
    id: 'w2',
    title: 'Scaled (all ages)',
    weight: '95/65',
    description:
      'Complete 30 clean & jerks at the prescribed weight as fast as you can. Touch and go reps are permitted. The athlete must be standing at full knee, hip, and elbow extension at the top of each jerk in order for the rep to count, and before lowering the bar back to the floor for the next rep.',
    instructions:
      'The athlete begins standing tall in front of the barbell. Once the timer begins counting the athlete may reach down, grab the barbell & begin the workout. Upon completion of the final clean & jerk the workout is over. The athlete’s score is their total time.',
  },
  {
    id: 'w3',
    title: 'Masters 35+',
    weight: '135/95',
    description:
      'Complete 30 clean & jerks at the prescribed weight as fast as you can. Touch and go reps are permitted. The athlete must be standing at full knee, hip, and elbow extension at the top of each jerk in order for the rep to count, and before lowering the bar back to the floor for the next rep.',
    instructions:
      'The athlete begins standing tall in front of the barbell. Once the timer begins counting the athlete may reach down, grab the barbell & begin the workout. Upon completion of the final clean & jerk the workout is over. The athlete’s score is their total time.',
  },
  {
    id: 'w4',
    title: 'Masters 35+ (Scaled)',
    weight: '95/65',
    description:
      'Complete 30 clean & jerks at the prescribed weight as fast as you can. Touch and go reps are permitted. The athlete must be standing at full knee, hip, and elbow extension at the top of each jerk in order for the rep to count, and before lowering the bar back to the floor for the next rep.',
    instructions:
      'The athlete begins standing tall in front of the barbell. Once the timer begins counting the athlete may reach down, grab the barbell & begin the workout. Upon completion of the final clean & jerk the workout is over. The athlete’s score is their total time.',
  },
]

export const listWorkouts = () => async (dispatch) => {
  try {
    dispatch({ type: WORKOUTS_LIST_REQUEST })

    // const { data } = await axios.get('/api/workouts')

    const data = initialWorkouts // temporary

    dispatch({ type: WORKOUTS_LIST_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: WORKOUTS_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
