import React from 'react'

import { Row, Col } from 'react-bootstrap'

import Card from '../../components/UI/Card'

import Styles from './Workout.module.scss'

const Workout = ({ workout }) => {
  const { title, description, instructions, weight } = workout
  return (
    <div className={Styles.workout}>
      <div className={Styles.title}>{title}</div>
      <Card className={Styles.card}>
        <Row>
          <Col md={3}>
            <h3>workout</h3>
            <p>“Grace”</p>
            <p>For time:</p>
            <p>30 Clean &amp; Jerks, {weight} lbs</p>
          </Col>
          <Col md={3} className={Styles.smhidden}>
          </Col>
          <Col md={6} className={Styles.reps}>
            <h3>score type</h3>
            <p>Total Time</p>
          </Col>
          <Col md={6} className={Styles.description}>
            <h3>description</h3>
            <p>{description}</p>
          </Col>
          {instructions && (
            <Col md={6} className={Styles.instructions}>
              <h3>instructions</h3>
              <p>{instructions}</p>
            </Col>
          )}
        </Row>
      </Card>
    </div>
  )
}

export default Workout
