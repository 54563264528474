import React from 'react'
import { useHistory } from 'react-router-dom'

import { Container, Row, Col, Button } from 'react-bootstrap'

import SmallLogo from '../../components/SmallLogo'

import Styles from './AboutScreen.module.scss'

const AboutScreen = () => {
  let history = useHistory()

  const signHandler = () => {
    window.location.href = "https://beyondthewhiteboard.com/earned/challenges/1"
  }

  return (
    <Container fluid className={Styles.gradient}>
      <Row>
        <Col>
          <div className={Styles.about}>
            <Row>
              <Col md={6} className={Styles.left}>
                <h2>About The Earned Series</h2>
                <p>
                  The Earned Series is a global community event for athletes to showcase their hard work and to “earn” a unique t-shirt reflective of their performance. 
                  This idea was born from a desire to celebrate the efforts of everyday athletes from all around the world. 
                  Gyms and garages in every country are filled with real people with busy, hectic lives, pushing themselves every day to get better. 
                  Somehow those people manage to make fitness a priority, and their dedication is what inspired us to create The Earned Series. 
                </p>
                <p>
                  Every person is at a different stage of their fitness journey, none more important than the other, which is why we’ve established 4 different levels for each Earned Series challenge. 
                  Participants' workout results will fall into one of the 4 levels.
                </p>
                <p>
                <b>Level 1:</b> Completing the scaled version of the workout.<br />
                <b>Level 2:</b> Anyone who can complete the workout prescribed, regardless of time.<br />
                <b>Level 3:</b> Requires participants to complete the workout Rx’d within a certain time cap that is challenging but realistic for many people.<br />
                <b>Level 4:</b> Participants must complete the workout Rx’d in a very competitive time cap that would place them around the top 10% in the world.
                </p>
                <p>
                  Each of these levels should be celebrated, and that’s exactly what we’re going to do. 
                  Keep in mind, performing a benchmark workout as Rx’d is a huge accomplishment in and of itself. 
                  Doing so places individuals in the top 10% of the ENTIRE WORLD population when it comes to fitness.
                </p>
                <p>
                  Participating in an Earned Series challenge is simple. 
                  Register for the challenge. 
                  Film yourself performing the workout. 
                  Log your result to btwb, and attach the video. 
                  ALL challenge results will be judged and verified, and will determine the Earned Shirt participants receive.
                </p>
                <p>
                  So why film the workout? 
                  First, filming your workout allows us to validate your effort. 
                  This makes it possible for us to provide a trustworthy, and fully verified leaderboard (the first of its kind). 
                  Also, we want participants to own their score, take pride in their results, and ultimately wear their unique Earned Shirt proudly. 
                  While there is a leaderboard and comparing scores is part of the fun, ultimately the Earned Series is a “You vs You” challenge commemorating an important milestone in your Fitness Journey.
                </p>
                <p>
                  Finally, the Earned Series wouldn’t be complete without giving back in some way. 
                  Each Earned Series Challenge will donate a portion of the proceeds to a specific charity. 
                  For The Grace Challenge, we're happy to be contributing to <b>One Tree Planted</b>, which plants one new tree for every dollar donated.
                </p>
                <p>
                  btwb is proud to be a part of your Fitness Journey. Now go earn it!
                </p>
              </Col>
              <Col md={6} className={Styles.right}>
                <div className={Styles.content}>
                  <h2>The Grace Challenge</h2>
                  <p>July 9th, 2021 – July 13, 2021</p>
                  <p className={Styles.soon}>Challenge is Closed</p>

                  <div className={Styles.action}>
                    {/*<Button
                      variant='primary'
                      className={Styles.btn}
                      onClick={signHandler}
                    >
                      Sign up
                    </Button>*/}

                  </div>

                  <div className={Styles.logos}>
                    <SmallLogo
                      topTitle='charity'
                      className={Styles.charity}
                      imageName="charity"
                    />
                    <p>Every earned shirt plants a tree.</p>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default AboutScreen
