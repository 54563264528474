import React from 'react'

import { Container, Row, Col, Button } from 'react-bootstrap'
import { Link, NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import Logo from '../Logo'

import { navLinks } from '../../constants/navLinks'

import { logout } from '../../actions/userAction'

import Styles from './Header.module.scss'

const Header = ({ onOpen }) => {
  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const logoutHandler = () => {
    dispatch(logout())
  }

  const links = navLinks.map((link) => (
    <NavLink key={link.id} to={link.route} activeClassName={Styles.selected}>
      <div className={Styles.navLink}>{link.title}</div>
    </NavLink>
  ))

  return (
    <header className={Styles.header}>
      <Container fluid>
        <Row>
          <Col>
            <div className={Styles.navbar}>
              <Link to={'/'}>
                <Logo isBtwb />
              </Link>
              <div className={Styles.nav}>{links}</div>
              {/*{userInfo ? (
                <Button
                  className={Styles.sign}
                  variant='primary'
                  onClick={logoutHandler}
                >
                  Sign Out
                </Button>
              ) : (
                <a href={'https://beyondthewhiteboard.com/earned/challenges/1'}>
                  <Button className={Styles.sign} variant='primary'>
                    Sign Up
                  </Button>
                </a>
              )}*/}
              <div className={Styles.togler} onClick={onOpen}></div>
            </div>
          </Col>
        </Row>
      </Container>
    </header>
  )
}

export default Header
