import React, { useState } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'

import ScrollToTop from './ScrollToTop'

import Header from './components/Header'
import Footer from './components/Footer'
import Drawer from './components/Drawer'

import HomeScreen from './screens/HomeScreen'
import AboutScreen from './screens/AboutScreen'
import WorkoutsScreen from './screens/WorkoutsScreen'
import FaqScreen from './screens/FaqScreen'
import DivisionsScreen from './screens/DivisionsScreen'
import EarnedShirtScreen from './screens/EarnedShirtScreen'
import SponsorsScreen from './screens/SponsorsScreen'
import SignIn from './screens/SignIn'
import SignUp from './screens/SignUp'
import SelectDivision from './screens/SelectDivision'
import SelectTshirt from './screens/SelectTshirt'
import Success from './screens/Success'

import Styles from './App.module.scss'

const App = () => {
  const [isOpenDrawer, setIsOpenDrawer] = useState(false)

  const openDrawerHandler = () => setIsOpenDrawer(true)
  const closeDrawerHandler = () => setIsOpenDrawer(false)

  return (
    <>
      <Router>
        <Header onOpen={openDrawerHandler} />
        <Drawer isOpen={isOpenDrawer} onClose={closeDrawerHandler} />
        <main className={Styles.main}>
          <ScrollToTop />
          <Switch>
            <Route path='/about' component={AboutScreen} />
            <Route path='/workouts' component={WorkoutsScreen} />
            <Route path='/faq' component={FaqScreen} />
            <Route path='/divisions' component={DivisionsScreen} />
            <Route path='/earnedshirt' component={EarnedShirtScreen} />
            <Route path='/sponsors' component={SponsorsScreen} />
            <Route path='/login' component={SignIn} />
            <Route path='/register' component={SignUp} exact />
            <Route path='/register/divisions' component={SelectDivision} />
            <Route path='/register/earnedshirt' component={SelectTshirt} />
            <Route path='/success' component={Success} />
            <Route path='/' component={HomeScreen} exact />
            <Redirect to='/' />
          </Switch>
        </main>
        <Footer />
      </Router>
    </>
  )
}

export default App
