import React, { useState } from 'react'

import { Carousel } from 'react-bootstrap'

import Workout from './Workout'
import Division from './Division'

import Styles from './CustomCarousel.module.scss'

const CustomCarousel = ({ workouts, divisions }) => {
  const [index, setIndex] = useState(0)

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex)
  }

  const renderCarouselItems = () => {
    if (workouts) {
      return workouts.map((workout) => (
        <Carousel.Item key={workout.id} className={Styles.item}>
          <Workout workout={workout} />
        </Carousel.Item>
      ))
    } else if (divisions) {
      return divisions.map((division) => (
        <Carousel.Item key={division.id} className={Styles.item}>
          <Division division={division} />
        </Carousel.Item>
      ))
    } else {
      return (
        <Carousel.Item className={Styles.item}>
          <p className='text-center'>No items</p>
        </Carousel.Item>
      )
    }
  }

  return (
    <Carousel
      interval={null}
      activeIndex={index}
      onSelect={handleSelect}
      className={Styles.carousel}
    >
      {renderCarouselItems()}
    </Carousel>
  )
}

export default CustomCarousel
