import React from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { Button } from 'react-bootstrap'
import Logo from '../Logo'

import PropTypes from 'prop-types'

import { navLinks } from '../../constants/navLinks'

import { logout } from '../../actions/userAction'

import Styles from './Drawer.module.scss'

const Drawer = ({ isOpen, onClose }) => {
  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const logoutHandler = () => {
    dispatch(logout())
  }

  const links = navLinks.map((link) => (
    <Link key={link.id} to={link.route}>
      <div className={Styles.link} onClick={onClose}>
        {link.title}
      </div>
    </Link>
  ))

  return (
    <div className={[Styles.drawer, isOpen ? Styles.open : ''].join(' ')}>
      <div className={Styles.top}>
        <div className={Styles.logoContainer} onClick={onClose}>
          <Link to={`/`}>
            <Logo isBtwb />
          </Link>
        </div>
        <div className={Styles.close} onClick={onClose}></div>
      </div>
      <div className={Styles.links}>
        {/*{userInfo ? (
          <Button
            className={Styles.sign}
            variant='primary'
            onClick={logoutHandler}
            block
          >
            Sign Out
          </Button>
        ) : (
          <Link to={'/login'}>
            <Button className={Styles.sign} block onClick={onClose}>
              Sign Up
            </Button>
          </Link>
        )}*/}
        {links}
      </div>
    </div>
  )
}

Drawer.defaultProps = {
  isOpen: false,
  onClose: () => null,
}

Drawer.propTypes = {
  isOpen: PropTypes.bool,
}

export default Drawer
