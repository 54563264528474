import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_RESET,
  NEW_USER_DETAILS_SAVE,
  NEW_USER_DETAILS_ADD_DIVISION,
  NEW_USER_DETAILS_ADD_TSHIRT,
  NEW_USER_DETAILS_RESET,
} from '../constants/userConstants'

export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { loading: true }
    case USER_LOGIN_SUCCESS:
      return { loading: false, userInfo: action.payload }
    case USER_LOGIN_FAIL:
      return { loading: false, error: action.payload }
    case USER_LOGOUT:
      return {}

    default:
      return state
  }
}

export const userRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_REGISTER_REQUEST:
      return { loading: true }
    case USER_REGISTER_SUCCESS:
      return { loading: false, userInfo: action.payload }
    case USER_REGISTER_RESET:
      return {}
    case USER_REGISTER_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const newUserDetailsReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case NEW_USER_DETAILS_SAVE:
      return { user: action.payload }
    case NEW_USER_DETAILS_ADD_DIVISION:
      return { user: { ...state.user, division: action.payload } }
    case NEW_USER_DETAILS_ADD_TSHIRT:
      return { user: { ...state.user, tShirtSize: action.payload } }
    case NEW_USER_DETAILS_RESET:
      return { user: {} }
    default:
      return state
  }
}
