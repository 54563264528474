import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import { Link, useHistory } from 'react-router-dom'
import { Button } from 'react-bootstrap'

import Logo from '../../components/Logo'
import ReadMoreModal from '../../components/ReadMoreModal'
import SignContainer from '../../components/UI/SignContainer'

import { addDivisionNewUserDetails } from '../../actions/userAction'

import Styles from './SelectDivision.module.scss'

import { DIVISIONS, divisions } from '../../constants/divisions'

const SelectDivision = () => {
  let history = useHistory()
  const [selectedType, setSelectedType] = useState(DIVISIONS.SCALED)
  const [isChecked, setIsChecked] = useState(false)
  const [showReadMoreModal, setShowReadMoreModal] = useState(false)
  const dispatch = useDispatch()

  const onChangeHandler = () => {
    setIsChecked((prev) => setIsChecked(!prev))
  }

  const onShowHandler = () => {
    setShowReadMoreModal(true)
  }

  const onCloseHandler = () => {
    setShowReadMoreModal(false)
  }

  const submitHandler = (e) => {
    e.preventDefault()
    const division = { ...divisions.find((item) => item.id === selectedType) }

    dispatch(addDivisionNewUserDetails(division.title))
    history.push('/register/earnedshirt')
  }

  return (
    <div className={Styles.division}>
      {showReadMoreModal && <ReadMoreModal onClose={onCloseHandler} />}
      <div className={Styles.gradient}>
        <Link to='/'>
          <Logo isBtwb />
        </Link>
      </div>
      <div className={Styles.choice}>
        <div className={Styles['small-logo']}>
          <Link to='/'>
            <Logo isBtwb />
          </Link>
        </div>
        <SignContainer className={Styles.box}>
          <h2>Divisions</h2>
          <p className={Styles.tip}>
            Select the division in which you want to participate
          </p>
          <div className={Styles.about} onClick={onShowHandler}>
            <i className='far fa-question-circle' />
            &nbsp;More About Divisions &amp; Workouts
          </div>

          <div className={Styles.separator} />

          <form className={Styles.form} onSubmit={submitHandler}>
            <div className={Styles.divisions}>
              {divisions.map(({ id, title, type }) => (
                <button
                  key={id}
                  type='button'
                  onClick={() => setSelectedType(type)}
                  className={[
                    Styles.item,
                    type === selectedType ? Styles.active : '',
                  ].join(' ')}
                >
                  {title}
                </button>
              ))}
            </div>

            <div className={Styles.formcheck}>
              <div className={Styles.custom}>
                <input
                  id='terms'
                  type='checkbox'
                  value={isChecked}
                  checked={isChecked}
                  onChange={onChangeHandler}
                />
                <label htmlFor='terms'></label>
              </div>

              <p>
                I acknowledge that I have read and agree to <br /> the&nbsp;
                <a href='#' title='Terms' target='_blank' rel='noreferrer'>
                  Terms &amp; Conditions
                </a>
                &nbsp;and&nbsp;
                <a href='#' title='Policy' target='_blank' rel='noreferrer'>
                  Privacy Policy.
                </a>
              </p>
            </div>

            <Button
              variant='primary'
              className={Styles.btn}
              type='submit'
              disabled={!isChecked}
              block
            >
              Create account
            </Button>
          </form>
        </SignContainer>
      </div>
    </div>
  )
}

export default SelectDivision
