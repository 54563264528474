import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Container, Row, Col } from 'react-bootstrap'

import Loader from '../../components/Loader'
import Message from '../../components/Message'
import Workout from './Workout'

import { listWorkouts } from '../../actions/workoutsAction'

import Styles from './WorkoutsScreen.module.scss'

const WorkoutsScreen = () => {
  const dispatch = useDispatch()
  const workoutsList = useSelector((state) => state.workoutsList)
  const { loading, error, workouts } = workoutsList

  useEffect(() => {
    dispatch(listWorkouts())
  }, [dispatch])

  return (
    <Container fluid>
      <Row>
        <Col>
          <div className={Styles.workouts}>
            <h2>Workouts</h2>
            {loading ? (
              <Loader />
            ) : error ? (
              <Message variant='danger'>{error}</Message>
            ) : (
              <>
                {workouts.map((workout) => (
                  <Workout key={workout.id} workout={workout} />
                ))}
                {workouts.length === 0 && <h3>Workouts not found</h3>}
              </>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default WorkoutsScreen
