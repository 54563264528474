import React from 'react'

import Styles from './Division.module.scss'

const Division = ({ division }) => {
  return (
    <div className={Styles.division}>
      <h3>{division.title}</h3>
      <p>{division.content}</p>
    </div>
  )
}

export default Division
