import React, { Component } from 'react'
import ErrorIndicator from '../ErrorIndicator'

export default class ErrorBoundry extends Component {
  state = {
    hasError: false,
  }

  componentDidCatch(error, info) {
    console.log('target error:', error)
    console.log('details error:', info)
    this.setState({ hasError: true })
  }

  render() {
    if (this.state.hasError) {
      return <ErrorIndicator />
    }
    return this.props.children
  }
}
