import React from 'react'
import { useHistory } from 'react-router-dom'

import { Image, Button } from 'react-bootstrap'

import SmallLogo from '../../components/SmallLogo'

import Styles from './HomeScreen.module.scss'

const HomeScreen = () => {
  let history = useHistory()

  const signHandler = () => {
    window.location.href = "https://beyondthewhiteboard.com/earned/challenges/1"
  }
  return (
    <>
      <div className={Styles.poster}></div>

      <div className={Styles.container}>
        <div className={Styles.right}>
          <div className={Styles.content}>
            <h2>The Grace Challenge</h2>
            <h3>Challenge details</h3>
            <p>July 9, 2021 – July 13, 2021</p>
            <p className={Styles.soon}>Challenge is Closed</p>

            <SmallLogo topTitle='Charity' className={Styles.charity} imageName="charity" />
            <p>Every earned shirt plants a tree.</p>

            <div className={Styles.action}>
              {/*<Button
                variant='primary'
                className={Styles.btn}
                onClick={signHandler}
              >
                Sign up
              </Button>*/}
            </div>

            <div className={Styles.textBox}>
              <h3>Price</h3>
              <p className={Styles.light}>
                Without earned shirt:&nbsp;<span>Free</span>
              </p>
              <p className={Styles.light}>
                With earned shirt:&nbsp;<span>$30</span>
              </p>
            </div>

            <div className={Styles.textBox}>
              <h3>Workout description</h3>
              <p>&quot;Grace&quot;</p>
              <p>For time:</p>
              <p>30 Clean &amp; Jerks</p>
            </div>

            <div className={[Styles.textBox, Styles.division].join(' ')}>
              <h3>Divisions</h3>
              <p>Rx’d (All ages)</p>
              <p>Scaled (All ages)</p>
              <p>Masters 35+ Rx’d</p>
              <p>Masters 35+ Scaled</p>
            </div>
            
            <div className={Styles.pdfs}>
              <p><a href="https://s3.amazonaws.com/media.btwb.co/Earned+Series/grace_rules.pdf">Rules Guide PDF</a></p>
              <p><a href="https://s3.amazonaws.com/media.btwb.co/Earned+Series/grace_lesson-plan.pdf">Gym Lesson Plan PDF</a></p>
            </div>

            <div className={[Styles.textBox, Styles.badge].join(' ')}>
              <h3>The Grace Challenge Badge</h3>
              <div className={Styles.icon}></div>
              <p>An exclusive btwb app badge based on your challenge score.</p>
            </div>

            <div className={[Styles.textBox, Styles.contact].join(' ')}>
              <h3>Contact</h3>
              <a href='mailto:support@btwb.com'>support@btwb.com</a>
            </div>
          </div>
        </div>

        <div className={Styles.left}>
          <div className={Styles.content}>
            <p>
              Welcome to The Grace Challenge presented by BTWB, the inaugural challenge in the Earned Series. 
              The Earned Series are global community events for athletes to showcase their hard work and earn gear reflective of their performance.
              This is a you versus you challenge to test your fitness, earn cool gear, and have fun doing it. 
              Fitness is a journey, with many milestones along the way worth celebrating. 
              Let’s start the celebrations by kicking off the series with <b>The Grace Challenge</b>!
            </p>
            <div className={Styles.howBlock}>
              How it works
              <ul>
                <li>Register</li>
                <li>Read the rules</li>
                <li>Log score & Attach video</li>
                <li>Receive T-shirt & Challenge Badge</li>
              </ul>
            </div>
            <div className={Styles.video}>
              {/*<button className={Styles.play}></button>*/}
              <Image src='./images/shirt-details.png' fluid className={Styles.big} />
            </div>
            <h2>Earn It!</h2>
            <p>
              The Earned Series is a first-of-its-kind challenge that respects all participant’s efforts. 
              EVERYONE will submit a video of their effort. 
              Leaderboard placing, t-shirt, and challenge badge are all tied to a result and a video that have been officially verified. 
              For the first time ever, participants can trust the placing and efforts of all participants in a worldwide competition/challenge.  
            </p>
            <div className={Styles.howBlock}>
              Earned T-shirts and challenge badges will have 4 different levels:
              <ul>
                <li>Level 1: Earned when the scaled version of the workout is completed</li>
                <li>Level 2: Earned when the challenge workout is performed as prescribed, regardless of time.</li>
                <li>Level 3: Earned when the challenge workout is performed in 4:00 or less. </li>
                <li>Level 4: Earned when the challenge workout is performed in 2:00 or less. These scores are in the top 10% of btwb Grace times.</li>
              </ul>
            </div>
            <p>With the Earned Series, "earned" is not just a word. It's actual proof you worked for your result, and you should feel proud.</p>

            <h2>Give Back To Your Gym</h2>
            <p>
              Some of the best places are independently owned gyms. 
              We love their unique character and cultures. 
              These are places where we set personal records and escape to when we need to de-stress. 
              Our mission has always been to make technology for these communities and we are excited to say we are taking another big step. 
              <b> Part of of the proceeds from each earned shirt will go back to your gym. </b> 
              Gyms interested in participating can get <a href="https://btwb.blog/2021/07/01/fitness-communities-discover-new-revenue-streams/">more information here</a>.  
            </p>
            {/*<div className={Styles.video}>
              <button className={Styles.play}></button>
              <Image src='./images/video_bg.png' fluid className={Styles.big} />
            </div>*/}
            <div className={Styles.cellar}>
              {/*<SmallLogo topTitle='Charity' className={Styles.charity} imageName="charity" />
              <p>For every shirt purchased a tree will be planted.</p>*/}

              <div className={[Styles.textBox, Styles.badge].join(' ')}>
                <h3>The Grace Challenge Badge</h3>
                <div className={Styles.icon}></div>
                <p>An exclusive btwb app badge based on your challenge score.</p>
              </div>

              <div className={[Styles.textBox, Styles.contact].join(' ')}>
                <h3>Contact</h3>
                <a href='mailto:support@btwb.com'>support@btwb.com</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default HomeScreen
