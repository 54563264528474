import React, { useRef } from 'react'
import ReactDOM from 'react-dom'
import Select from 'react-select'

import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { Formik } from 'formik'
import * as Yup from 'yup'

import { Link } from 'react-router-dom'
import { Button } from 'react-bootstrap'

import Logo from '../../components/Logo'
import Input from '../../components/UI/Input'

import SignContainer from '../../components/UI/SignContainer'

import { saveNewUserDetails } from '../../actions/userAction'

import Styles from './SignUp.module.scss'

const SignupSchema = Yup.object().shape({
  firstName: Yup.string()
    .max(15, 'Must be 15 characters or less')
    .required('First name required'),
  lastName: Yup.string()
    .max(20, 'Must be 20 characters or less')
    .required('Last name required'),
  email: Yup.string().email('Invalid email address').required('Email required'),
  password: Yup.string()
    .required('Password required.')
    .min(6, 'Min length 6 characters.'),
  gender: Yup.string().required('Gender required'),
  birthDay: Yup.string().required('Required'),
  birthMonth: Yup.string().required('Required'),
  birthYear: Yup.string().required('Required'),
})

const Months = [
  { value: 0, label: 'January' },
  { value: 1, label: 'February' },
  { value: 2, label: 'March' },
  { value: 3, label: 'April' },
  { value: 4, label: 'May' },
  { value: 5, label: 'June' },
  { value: 6, label: 'July' },
  { value: 7, label: 'August' },
  { value: 8, label: 'September' },
  { value: 9, label: 'October' },
  { value: 10, label: 'November' },
  { value: 11, label: 'December' },
]

const year = new Date().getFullYear()
const years = Array.from(new Array(50), (_, idx) => year - idx).map((item) => ({
  label: item,
  value: item,
}))
const days = Array.from(new Array(31), (_, idx) => idx + 1).map((item) => ({
  label: item,
  value: item,
}))

const initValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  gender: '',
  birthDay: '',
  birthMonth: '',
  birthYear: '',
}

const RegisterPage = () => {
  const dispatch = useDispatch()
  const dateSelectRef = useRef()
  let history = useHistory()

  const onSubmitHandler = (values, { setSubmitting }) => {
    setTimeout(() => {
      dispatch(saveNewUserDetails(values))

      setSubmitting(false)
      history.push('/register/divisions')
    }, 400)
  }

  return (
    <div className={Styles.create}>
      <div className={Styles.gradient}>
        <Link to='/'>
          <Logo isBtwb />
        </Link>
      </div>

      <div className={Styles.sign} id='sign'>
        <div className={Styles['small-logo']}>
          <Link to='/'>
            <Logo isBtwb />
          </Link>
        </div>
        <SignContainer className={Styles.box}>
          <h2>Create an account</h2>

          <Formik
            initialValues={initValues}
            validationSchema={SignupSchema}
            onSubmit={onSubmitHandler}
            validateOnBlur
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              isValid,
              handleSubmit,
              dirty,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit} className={Styles.form}>
                <div className={[Styles['form-group'], Styles.names].join(' ')}>
                  <Input
                    label='firstName'
                    name='firstName'
                    type='firstName'
                    placeholder='Enter your first name'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.firstName}
                    error={touched.firstName && errors.firstName}
                    required
                  />

                  <Input
                    label='lastName'
                    name='lastName'
                    type='lastName'
                    placeholder='Enter your last name'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.lastName}
                    error={touched.lastName && errors.lastName}
                    required
                  />
                </div>

                <div className={Styles['form-group']}>
                  <Input
                    label='email'
                    name='email'
                    type='email'
                    placeholder='Enter your email'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    error={touched.email && errors.email}
                    required
                  />
                </div>

                <div className={Styles['form-group']}>
                  <Input
                    label='password'
                    name='password'
                    type='password'
                    placeholder='Enter your password'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    error={touched.password && errors.password}
                    required
                  />
                </div>

                <div className={Styles.gender}>
                  <div className={Styles.title}>
                    gender&nbsp;<span>*</span>
                  </div>

                  <div role='group' className={Styles['form-group']}>
                    <div>
                      <input
                        className={Styles.radioinput}
                        type='radio'
                        id='male'
                        name='gender'
                        value='Male'
                        onChange={handleChange}
                      />
                      <label htmlFor='male' className={Styles.radiolabel}>
                        Male
                      </label>
                    </div>

                    <div>
                      <input
                        className={Styles.radioinput}
                        type='radio'
                        id='female'
                        name='gender'
                        value='Female'
                        onChange={handleChange}
                      />
                      <label htmlFor='female' className={Styles.radiolabel}>
                        Female
                      </label>
                    </div>
                  </div>
                  <div className={Styles.showError}>
                    {touched.gender && errors.gender && errors.gender}
                  </div>
                </div>

                <div className={Styles.birthday}>
                  <div
                    className={Styles.title}
                    onClick={() => dateSelectRef.current.focus()}
                  >
                    birthday&nbsp;<span>*</span>
                  </div>
                  <div className={Styles['form-group']}>
                    <Select
                      className={Styles.birthdayItem}
                      classNamePrefix='birthdayItem'
                      ref={dateSelectRef}
                      placeholder='Date'
                      menuPlacement='auto'
                      menuPosition='fixed'
                      indicatorSeparator={false}
                      options={days}
                      onChange={(day) => setFieldValue('birthDay', day.value)}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                    />

                    <Select
                      className={Styles.birthdayItem}
                      classNamePrefix='birthdayItem'
                      menuPlacement='auto'
                      placeholder='Month'
                      menuPosition='fixed'
                      options={Months}
                      onChange={(month) =>
                        setFieldValue('birthMonth', month.value)
                      }
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                    />

                    <Select
                      className={Styles.birthdayItem}
                      classNamePrefix='birthdayItem'
                      menuPlacement='auto'
                      placeholder='Year'
                      menuPosition='fixed'
                      options={years}
                      onChange={(year) =>
                        setFieldValue('birthYear', year.value)
                      }
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                    />
                  </div>
                  <div className={Styles.showError}>
                    <span>
                      {touched.birthDay && errors.birthDay && errors.birthDay}
                    </span>
                    <span>
                      {touched.birthMonth &&
                        errors.birthMonth &&
                        errors.birthMonth}
                    </span>
                    <span>
                      {touched.birthYear &&
                        errors.birthYear &&
                        errors.birthYear}
                    </span>
                  </div>
                </div>

                <div className={Styles.action}>
                  <Button
                    className={Styles.btn}
                    variant='primary'
                    type='submit'
                    disabled={!isValid && !dirty}
                    block
                  >
                    Next
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </SignContainer>
      </div>
    </div>
  )
}

const SignUp = () => {
  return (
    <React.Fragment>
      {ReactDOM.createPortal(
        <RegisterPage />,
        document.getElementById('register-page')
      )}
    </React.Fragment>
  )
}

export default SignUp
