import { Container, Row, Col } from 'react-bootstrap'

import Styles from './SignContainer.module.scss'

const SignContainer = ({ children, className }) => {
  return (
    <Container fluid className={Styles.overflow}>
      <Row>
        <Col xs={12}>
          <div className={[Styles.box, className ? className : ''].join(' ')}>
            {children}
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default SignContainer
