import React from 'react'

import Styles from './Input.module.scss'

const Input = ({ label, name, type, required, className, error, ...rest }) => {
  return (
    <div className={Styles.containerInput}>
      {label && (
        <label className={Styles.label} htmlFor={name}>
          {label}
          {required && <span>&nbsp;*</span>}
        </label>
      )}
      <input
        name={name}
        type={type || 'text'}
        id={name}
        className={[Styles.input, className ? className : ''].join(' ')}
        {...rest}
      />
      {error && <div className={Styles.error}>{error}</div>}
    </div>
  )
}

export default Input

// import React from 'react';
// import Styles from './Input.module.scss'

// export default ({ label, name, type, onChange, placeholder }) => (
//   <div className={Styles.containerInput}>
//     {
//       label
//       ? <label className={Styles.label} htmlFor={name}>{label}</label>
//       : null
//     }
//     <input
//       name={name}
//       type={type || 'text'}
//       id={name}
//       onChange={onChange}
//       className={Styles.input}
//       placeholder={placeholder}
//     />
//   </div>
// );
