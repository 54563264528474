import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import Logo from '../Logo'

import Styles from './Footer.module.scss'

const Footer = () => {
  return (
    <footer className={Styles.footer}>
      <Container fluid>
        <Row>
          <Col>
            <div className={Styles.box}>
              <Logo />
              <div className={Styles.social}>
                <a
                  href='https://www.facebook.com/btwbapp'
                  title='Facebook'
                  target='_blank'
                  rel='noreferrer'
                >
                  <i className='fab fa-facebook-f' />
                </a>
                <a
                  href='https://www.instagram.com/btwbapp/'
                  title='Instagram'
                  target='_blank'
                  rel='noreferrer'
                >
                  <i className='fab fa-instagram'></i>
                </a>
                <a
                  href='https://www.linkedin.com/company/btwb/'
                  title='Linkedin'
                  target='_blank'
                  rel='noreferrer'
                >
                  <i className='fab fa-linkedin-in'></i>
                </a>
                <a
                  href='https://twitter.com/btwb'
                  title='Twitter'
                  target='_blank'
                  rel='noreferrer'
                >
                  <i className='fab fa-twitter'></i>
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer
