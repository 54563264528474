import React from 'react'

import { Container, Row, Col } from 'react-bootstrap'

import Styles from './SponsorsScreen.module.scss'

const SponsorsScreen = () => {
  return (
    <Container fluid>
      <Row>
        <Col className={Styles.sponsors}>
          <h2>Sponsors</h2>
          <p>
            Risus turpis libero, laoreet molestie ut a diam. Amet nascetur urna
            nam aliquet ut ac adipiscing.
          </p>
          <div className={Styles.logos}>
            <div className={Styles.row}>
              <div className={Styles.world}></div>
              <div className={Styles.ufc}></div>
              <div className={Styles.golds}></div>
              <div className={[Styles.crunch, Styles.hide].join(' ')}></div>
              <div className={[Styles.cc, Styles.hide].join(' ')}></div>
            </div>

            <div className={Styles.row}>
              <div className={Styles.ufc}></div>
              <div className={Styles.world}></div>
              <div className={Styles.crunch}></div>
              <div className={[Styles.cc, Styles.hide].join(' ')}></div>
              <div className={[Styles.golds, Styles.hide].join(' ')}></div>
            </div>

            <div className={Styles.row}>
              <div className={Styles.world}></div>
              <div className={Styles.ufc}></div>
              <div className={Styles.golds}></div>
              <div className={[Styles.crunch, Styles.hide].join(' ')}></div>
              <div className={[Styles.cc, Styles.hide].join(' ')}></div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default SponsorsScreen
