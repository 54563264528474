const DIVISIONS = {
  RXD: 'RXD',
  SCALED: 'SCALED',
  MASTERS: 'MASTERS',
}

const divisions = [
  {
    id: DIVISIONS.RXD,
    title: 'Rx’d 16-54',
    type: DIVISIONS.RXD,
  },
  {
    id: DIVISIONS.SCALED,
    title: 'Scaled 16-54',
    type: DIVISIONS.SCALED,
  },
  {
    id: DIVISIONS.MASTERS,
    title: 'Masters 55+',
    type: DIVISIONS.MASTERS,
  },
]

export { DIVISIONS, divisions }
