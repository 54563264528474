import React, { useState } from 'react'

import { Container, Row, Col, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom';

import Questions from './Questions'

import Styles from './FaqScreen.module.scss'

const initialQuestions = [
  {
    sectionTitle: 'General',
    id: 'q1',
    open: true,
    questions: [
      {
        title: 'When does the challenge start/end?',
        answer:
          'The Grace Challenge starts at 9am PST on July 9th and ends at 5pm PST on July 13th. Registration will remain open through the close of the challenge on July 13th. All scores and videos must be submitted by 5pm PST on July 13th in order to be eligible for leaderboard placing, challenge badge, and earned t-shirt (if purchased).',
      },
      {
        title: 'How do I register?',
        answer:
          'Use the “Sign-up” button on the Challenge home page <a href="https://challenges.btwb.com">here</a>. Registration will remain open until 5pm PST on July 13th. A btwb account is required for participation. Participants may log into an existing btwb account or create a brand new account. All new accounts will come with a 30-day free btwb trial.',
      },
      {
        title: 'Do I need to have a btwb subscription to participate?',
        answer:
          'Participants will need to have a btwb subscription in order to participate in the Grace Challenge. Existing btwb users with paying individual subscriptions, or subscriptions through their gym, are fine as is. Participants who create brand new btwb accounts during the registration process will be granted a free 30-day btwb trial subscription. The trial subscription will be good for 30 days from the point of registration. New btwb users will be required to set up an individual paying subscription once the free trial expires if they wish to continue to use btwb.',
      },
      {
        title: 'Do I need to be part of a gym on btwb to participate?',
        answer:
          'btwb accounts do NOT have to be associated with a specific gym on btwb. However, a portion of the proceeds will go to the btwb gym a participant is associated with. In order to give gyms credit for registrations, participants need to make sure their btwb accounts are associated with the proper gym by the close of the Challenge.',
      },
      {
        title: 'Can I switch divisions after registering?',
        answer:
          'Participants will be able to switch divisions after the registration process is completed. They will be able to make the switch from the Grace Challenge screen located in the btwb app.',
      },
      {
        title: 'How do the levels work?',
        answer:
          'Earned Series challenges utilize a 4-level system. Each logged result will be associated with one of the 4 levels. The levels are as follows:<br/><br/>Level 1: Completing the scaled version of the workout.<br/>Level 2: Completing the workout prescribed, regardless of time.<br/>Level 3: Completing the workout Rx’d within a certain time cap that is challenging but realistic for many people.<br/>Level 4: Completing the workout RX’d in a very competitive time cap that would place he/she around the top 10% in the world.<br/><br/>Each level represents an important milestone in someone’s fitness journey. Participants will receive challenge badges and earned shirts (if purchased) based on the level their result earns them.',
      },
    ]
  },
  {
    sectionTitle: 'Earned Shirts',
    id: 'q2',
    open: false,
    questions: [
      {
        title: 'Which T-shirt will I earn?',
        answer:
          'Earned shirts will depend on logged results and video verification. Once official video verification has taken place, participants will receive the shirt matching the level of their result. There are 4 possible result levels (outlined below), and the shirt will have unique elements representing each level.<br/><br/>Level 1: Completing the scaled version of the workout. <br/>Level 2: Completing the workout prescribed, regardless of time.<br/>Level 3: Completing the workout Rx’d within a certain time cap that is challenging but realistic for many people.<br/>Level 4: Completing the workout RX’d in a very competitive time cap that would place he/she around the top 10% in the world.<br/><br/>',

      },
      {
        title: 'What kind of T-shirt is it?',
        answer:
          'Earned Shirt options include a Unisex and Women\'s Tri-Blend Tee in various sizes.',

      },
      {
        title: 'When will I receive my shirt?',
        answer:
          'It can take up to 5 weeks for the print shop to create your unique shirt. Once your shirt has been created, it will take an average shipping time of 4 business days depending on the delivery destination. BTWB is not responsible for lost or stolen shipments.',

      },
      {
        title: 'How does international shipping work?',
        answer:
          'International shipping is available. It can take up to 5 weeks for the print shop to create your unique shirt. Once your shirt has been created it will ship in accordance with the shipping method you selected. For example, if you select and pay for “3-day Shipping”, it could still take up to 5 weeks for the print shop to create your shirt and then it would be sent with 3-day shipping. Please keep in mind that many countries have their own unique requirements and/or fees for packages. BTWB is not responsible for any additional fees, requirements, documents, etc that may be required. It is your responsibility to ensure all requirements are met. BTWB is not responsible for lost or stolen shipments.',

      },
      {
        title: 'Can I buy a shirt if I didn’t order one during registration?',
        answer:
          'Yes, but you\'ll need to email <a href="mailto:support@btwb.com">support@btwb.com</a>. We\'ll provide instructions for purchasing an Earned Shirt.',

      },
      {
        title: 'How do I return/exchange my shirt?',
        answer:
          'If your shirt does not fit or has a defect that is our fault, you may exchange the item. Please send an email to support@btwb.com. In the subject line state the name of the challenge and “t-shirt exchange”. Please include your order number in the email as well as your complete name and mailing address. In the email please tell us the problem and if you wish to exchange the shirt. If you need a different size, be sure to include the size you wish to receive. If your shirt arrived with a material defect, please state what the defect was and include a photo of the defect. It could take up to 4-6 weeks to receive a new shirt.',

      },
    ]
  },
  {
    sectionTitle: 'btwb',
    id: 'q3',
    open: false,
    questions: [
      {
        title: 'How do I log a result for the Challenge workout?',
        answer:
          'Participants will log scores to the workout from the Grace Challenge screen in the btwb app. Click on the “Log” button, then follow each of the steps in the process.',

      },
      {
        title: 'Can I log multiple scores?',
        answer:
          'Yes. If participants perform the workout multiple times, they can log each score from the Grace Challenge screen in the btwb app. The challenge will automatically replace any previously logged score with a better logged score, so participants are encouraged to log as many results as they want.',

      },
      {
        title: 'Can I edit my result?',
        answer:
          'Yes. Participants can edit their logged score any time before July 13th @5pm PST.',

      },
      {
        title: 'How do I attach a video to my result?',
        answer:
          'Videos can be attached one of two ways. The first way happens during the score logging process. The final screen will prompt a user to attach a video link. Both Youtube and Vimeo links are acceptable. If a user chooses not to attach a video link while logging, they can always return to the result at a later time to attach the video link. In this case, users can find previously logged results on The Grace Challenge screen in the app. They can click on "Go to results", then on the "Edit" button for the result to be edited. This will take them back through the logging process in order to attach a video on the final step.',

      },
      {
        title: 'What format does my video have to be in?',
        answer:
          'Both Youtube and Vimeo links are acceptable.',

      },
      {
        title: 'What is the latest date/time I can attach a video?',
        answer:
          'Videos can be attached to results all the way up until the challenge officially closes at 5pm PST on July 13. If a video has not been attached to a challenge result by the deadline, the submission will be ineligible to receive a leaderboard placing, earned shirt, and challenge badge. There are no exceptions.',

      },
    ]
  },
  {
    sectionTitle: 'Videos',
    id: 'q4',
    open: false,
    questions: [
      {
        title: 'Do I need a video?',
        answer:
          'Yes! It\'s what separates an Earned Series challenge from other challenges out there. Everyone needs a video for Earned Series challenges if they want to appear on the leaderboard for their division, receive an earned shirt (if paid for), and receive an exclusive challenge badge. Failure to attach a video will exclude participants from each of those items.',

      },
      {
        title: 'What are the specific video guidelines?',
        answer:
          'The barbell & plates should be filmed in a way that allows the loads to be clearly identified. Videos need to be uncut & unedited in order to accurately display the performance. Videos shot with a fisheye lens or similar lens may be rejected, we recommend not using such lenses. The clock or timer must be visible during the entire workout. Phone timers, apps & stopwatches are acceptable as long as they are in frame and clearly visible the entire time. Ensure the athlete has ample space to safely perform all aspects of the workout & all potential obstructions have been removed. The video should be filmed in such a way as to clearly capture the entire range of motion required for the workout and allow for easy verification of the movement standards.<br/><br/>At the start of the video the athlete should be in frame, state their name, the challenge they are performing and which workout variation they choose to do (Rx’d, Scaled, etc.). Next, the barbell & plates should be filmed in a way that allows the loads to be clearly identified. Keep in mind everything needs to be filmed in one continuous, unedited video.<br/><br/>Videos need to be uploaded and attached to your btwb result no later than Tuesday, July 13, 2021 at 5pm PST. Be sure to allow sufficient time to submit your score and attach your video.<br/><br/>You can view video submission guidelines <a href="/earnedshirt">here</a>.',

      },
      {
        title: 'What if my video does NOT meet the requirements?',
        answer:
          'In this case your video would not be able to be validated and therefore you would not be able to earn a shirt or placement on the leaderboard.',

      },
    ]
  },
  {
    sectionTitle: 'Judging',
    id: 'q5',
    open: false,
    questions: [
      {
        title: 'Do I need a judge when I do the workout?',
        answer:
          'You won’t need a judge while performing the actual workout. Btwb has its own team of judges that will be reviewing and validating each video after the challenge has officially closed.',

      },
      {
        title: 'How will the videos be verified?',
        answer:
          'BTWB will be overseeing the video verification process to ensure the required standards are met.',

      },
      {
        title: 'Will penalties be assessed?',
        answer:
          'Yes, if necessary penalties will be assessed on a case by case basis as determined during the video review process.',

      },
      {
        title: 'How does this affect earned shirts/badges, and leaderboard placing?',
        answer:
          'Any penalties assessed may result in an adjustment to the athlete’s time. This adjustment in time may result in the athlete’s position on the leaderboard shifting. It may also affect which shirt and badge the athlete’s score earns. Athletes are only eligible for the leaderboard placement, shirt and badge earned by their score after any penalties have been assessed if necessary.',

      },
      {
        title: 'When/how will I know if any penalties have been assessed?',
        answer:
          'If your performance is assessed a penalty you will be informed by email within two weeks of the end of the Challenge.',

      },
      {
        title: 'Can I challenge any assessed penalties?',
        answer:
          'No. All assessed penalties will be final.',

      },
      {
        title: 'When can I expect my score to be validated?',
        answer:
          'Scores should be validated within 10 days of the end of the Challenge.',

      },
    ]
  },
  {
    sectionTitle: 'Charity',
    id: 'q6',
    open: false,
    questions: [
      {
        title: 'Who are One Tree Planted?',
        answer:
          'For each shirt purchased btwb will be donating to One Tree Planted to help plant trees across the globe. Learn more about their organization at <a href="https://onetreeplanted.org">https://onetreeplanted.org</a>.',

      },
    ]
  },
]

const FaqScreen = () => {
  const [questions, setQuestions] = useState(initialQuestions)

  const toggleQuestion = (id) => {
    setQuestions((prev) => {
      const idx = prev.findIndex((item) => item.id === id)
      const updatedQuestion = { ...prev.find((item) => item.id === id) }
      updatedQuestion.open = !updatedQuestion.open
      return [...prev.slice(0, idx), updatedQuestion, ...prev.slice(idx + 1)]
    })
  }

  return (
    <Container>
      <Row>
        <Col className={Styles.faq}>
          <h2>Frequently asked questions</h2>
          <Questions questions={questions} onToggle={toggleQuestion} />
          <div className={Styles.contact}>
            <h2>Do you still have questions?</h2>
            <h3>Feel free to reach out with any questions you might have. We're here to help. </h3>
            <a href={'mailto:support@btwb.com'}>
              <Button variant='primary' className={Styles.btn}>
                Contact us
              </Button>
            </a>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default FaqScreen
