import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { useDispatch, useSelector } from 'react-redux'

import { Tabs, Tab } from 'react-bootstrap'

import Loader from '../../components/Loader'
import Message from '../../components/Message'
import CustomCarousel from './CustomCarousel'
import Workout from './Workout'
import Division from './Division'

import { listWorkouts } from '../../actions/workoutsAction'
import { listDivisions } from '../../actions/divisionsAction'

import Styles from './ReadMoreModal.module.scss'

const Modal = ({ onClose }) => {
  const [key, setKey] = useState('divisions')

  const dispatch = useDispatch()
  const workoutsList = useSelector((state) => state.workoutsList)
  const {
    loading: loadingWorkouts,
    error: errorWorkouts,
    workouts,
  } = workoutsList

  const divisionsList = useSelector((state) => state.divisionsList)
  const {
    loading: loadingDivisions,
    error: errorDivisions,
    divisions,
  } = divisionsList

  useEffect(() => {
    dispatch(listDivisions())
    dispatch(listWorkouts())
  }, [dispatch])

  return (
    <div className={Styles.backdrop} onClick={onClose}>
      <div className={Styles.modal} onClick={(e) => e.stopPropagation()}>
        <div className={Styles.close} onClick={onClose}></div>
        <Tabs
          id='controlled-tab'
          transition={false}
          activeKey={key}
          onSelect={(k) => setKey(k)}
        >
          <Tab eventKey='divisions' title='Divisions'>
            {loadingDivisions ? (
              <Loader />
            ) : errorDivisions ? (
              <Message variant='danger'>{errorDivisions}</Message>
            ) : (
              <>
                <CustomCarousel divisions={divisions} />
                <div className={Styles.itemsSmall}>
                  {divisions.map((division) => (
                    <div className={Styles.divis} key={division.id}>
                      <Division division={division} />
                    </div>
                  ))}
                </div>
              </>
            )}
          </Tab>
          <Tab eventKey='workouts' title='Workouts'>
            {loadingWorkouts ? (
              <Loader />
            ) : errorWorkouts ? (
              <Message variant='danger'>{errorWorkouts}</Message>
            ) : (
              <>
                <CustomCarousel workouts={workouts} />
                <div className={Styles.itemsSmall}>
                  {workouts.map((workout) => (
                    <div className={Styles.work} key={workout.id}>
                      <Workout workout={workout} />
                    </div>
                  ))}
                </div>
              </>
            )}
          </Tab>
        </Tabs>
      </div>
    </div>
  )
}

const ReadMoreModal = ({ onClose }) => {
  return ReactDOM.createPortal(
    <Modal onClose={onClose} />,
    document.getElementById('modal-root')
  )
}

export default ReadMoreModal
