import React from 'react'
import PropTypes from 'prop-types'

import Styles from './Logo.module.scss'

const Logo = ({ isBtwb }) => {
  return (
    <div className={[Styles.logo, isBtwb ? Styles.btwb : ''].join(' ')}></div>
  )
}

Logo.defaultProps = {
  isBtwb: false,
}

Logo.propTypes = {
  isBtwb: PropTypes.bool,
}

export default Logo
