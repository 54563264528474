import { Row, Col } from 'react-bootstrap'

import Styles from './Workout.module.scss'

const Workout = ({ workout }) => {
  return (
    <>
      <h3>{workout.title}</h3>
      <div className={Styles.card}>
        <Row>
          <Col md={6}>
            <h4>Workout</h4>
            <p>
              “Grace”
              <br />
              For time:
              <br />
              30 Clean &amp; Jorks, 135/95
            </p>
          </Col>
          <Col md={6}>
            <h4>Score type</h4>
            <p>Total Time</p>
          </Col>
          <Col md={12} className={Styles.description}>
            <h4>Description</h4>
            <p>{workout.description}</p>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default Workout
